
import React from 'react';

const mentors = [
    {
      id: 1,
      name: "Shiva",
      avatar: "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=400",
      field: "Full Stack Developer",
      followers: 1200,
      bio: "Passionate about building scalable web applications and teaching others.",
      interview: {
        title: "Full Stack Development",
        nextScheduled: "Jan 28th, 2025",
        conductedBy: "Shiva",
        details: "Join us for a comprehensive session on full-stack web development covering the latest frameworks and best practices.",
      }
    },
    {
      id: 2,
      name: "Aditi maha",
      avatar: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600",
      field: "UI/UX Designer",
      followers: 1500,
      bio: "Creating intuitive user experiences with a focus on accessibility and simplicity.",
      interview: {
        title: "UI/UX Design",
        nextScheduled: "Feb 3rd, 2025",
        conductedBy: "Aditi",
        details: "A deep dive into the principles of UI/UX design, prototyping tools, and design systems.",
      }
    },
    {
      id: 3,
      name: "Ravi mehera",
      avatar: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=400",
      field: "Data Scientist",
      followers: 1100,
      bio: "Specializing in machine learning algorithms and data visualization.",
      interview: {
        title: "Data Science & Machine Learning",
        nextScheduled: "Feb 5th, 2025",
        conductedBy: "Ravi",
        details: "Explore the world of data science and machine learning, including data preprocessing and model building.",
      }
    },
    {
      id: 4,
      name: "Sanya deju",
      avatar: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600",
      field: "Mobile App Developer",
      followers: 2000,
      bio: "Building cross-platform mobile apps with a focus on performance and usability.",
      interview: {
        title: "Mobile App Development",
        nextScheduled: "Jan 30th, 2025",
        conductedBy: "Sanya",
        details: "Learn about mobile app development, including React Native and native Android/iOS development.",
      }
    },
    {
      id: 5,
      name: "Akash singh",
      avatar: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=400",
      field: "DevOps Engineer",
      followers: 800,
      bio: "DevOps enthusiast with a passion for automation and continuous integration.",
      interview: {
        title: "DevOps & Automation",
        nextScheduled: "Feb 7th, 2025",
        conductedBy: "Akash",
        details: "Get a hands-on look at DevOps best practices and the automation tools that streamline workflows.",
      }
    },
    {
      id: 6,
      name: "Meera desai",
      avatar: "https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=400",
      field: "AI/ML Engineer",
      followers: 1300,
      bio: "AI specialist focused on deep learning and neural networks.",
      interview: {
        title: "AI/ML Engineering",
        nextScheduled: "Feb 10th, 2025",
        conductedBy: "Meera",
        details: "Explore cutting-edge AI techniques, including deep learning and neural networks for real-world applications.",
      }
    }
  ];
  const meetings = [
    {
      id: 1,
      avatar: "https://randomuser.me/api/portraits/men/1.jpg",
      name: "John Doe",
      position: "Software Engineer",
      topic: "Full Stack Development",
      date: "2025-01-25",
      type: "Paid",
      stars: 4,
      feedback: "Great performance, but needs to improve on system design.",
      status: "Good"
    },
    {
      id: 2,
      avatar: "https://randomuser.me/api/portraits/men/2.jpg",
      name: "Jane Smith",
      position: "Frontend Developer",
      topic: "React.js Interview",
      date: "2025-01-23",
      type: "Free",
      stars: 3,
      feedback: "Solid understanding, but struggled with Redux.",
      status: "Improve"
    },
    {
      id: 3,
      avatar: "https://randomuser.me/api/portraits/men/3.jpg",
      name: "Michael Johnson",
      position: "Backend Developer",
      topic: "Node.js & Express",
      date: "2025-01-20",
      type: "Paid",
      stars: 5,
      feedback: "Excellent knowledge of backend systems.",
      status: "Good"
    },
    {
      id: 4,
      avatar: "https://randomuser.me/api/portraits/men/4.jpg",
      name: "Sara Lee",
      position: "Mobile Developer",
      topic: "React Native",
      date: "2025-01-18",
      type: "Paid",
      stars: 4,
      feedback: "Good coding skills, but needs to work on app performance.",
      status: "Good"
    },
    {
      id: 5,
      avatar: "https://randomuser.me/api/portraits/men/5.jpg",
      name: "David Wilson",
      position: "Data Scientist",
      topic: "Machine Learning",
      date: "2025-01-15",
      type: "Free",
      stars: 2,
      feedback: "Basic understanding, but needs a lot of improvement in ML algorithms.",
      status: "Improve"
    },
    {
      id: 6,
      avatar: "https://randomuser.me/api/portraits/men/6.jpg",
      name: "Emily Brown",
      position: "DevOps Engineer",
      topic: "CI/CD Pipeline",
      date: "2025-01-10",
      type: "Paid",
      stars: 3,
      feedback: "Good understanding of basic concepts, but could improve on automation tools.",
      status: "Improve"
    }
  ];
  



const MockInt = () => {
    // const [subscriptions, setSubscriptions] = useState(mentors);

    const handleJoinInterview = (id) => {
        // Implement follow functionality
        console.log("Followed mentor ID:", id);
    };

    const handleScheduleInterview = (id) => {
        // Implement subscribe functionality
        console.log("Subscribed to mentor ID:", id);
    };

    return (
        <>
            <div className="mb-6">
                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className="text-purple-600">Mock-Interview</span>
                </div>
            </div>

            <div className="min-h-screen bg-gray-100 dark:bg-slate-950 text-gray-900 dark:text-white p-6 px-4 md:px-10">
                {/* Mentors Section */}
                <div className="flex items-center justify-between">
                    <h2 className="text-2xl font-bold mb-6 text-left">Mock-Interview</h2>
                    <a href="#" className="text-blue-600 hover:underline text-sm">See All</a>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  {mentors.map((mentor) => (
    <div
      key={mentor.id}
      className="bg-white dark:bg-slate-900 rounded-lg p-5 shadow hover:shadow-lg transition text-center"
    >
      <div className="flex flex-col items-center mb-4">
        <img
          src={mentor.avatar}
          alt={mentor.name}
          className="w-24 h-24 rounded-full object-cover mb-4"
        />
        <h3 className="text-lg font-semibold text-indigo-700">{mentor.name}</h3>
        {/* <p className="text-sm text-gray-500 dark:text-gray-400">{mentor.field}</p> */}
        {/* <p className="text-xs text-gray-400">{mentor.followers} Followers</p> */}
      </div>
      {/* <p className="text-gray-600 dark:text-gray-400 text-center mb-4 text-sm">{mentor.bio}</p> */}

      {/* Interview Details Section */}
      <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg mt-4 text-left">
        <h4 className="font-semibold text-indigo-600 dark:text-indigo-400 text-sm">
          {mentor.interview.title} Interview
        </h4>
        <p className="text-xs text-gray-600 dark:text-gray-300 mt-2">
          <strong>Next Scheduled:</strong> {mentor.interview.nextScheduled}
        </p>
        <p className="text-xs text-gray-600 dark:text-gray-300">
          <strong>Conducted By:</strong> {mentor.interview.conductedBy}
        </p>
        <p className="text-xs text-gray-600 dark:text-gray-300 mt-2">
          <strong>Details:</strong> {mentor.interview.details}
        </p>
      </div>

     
  {/* Join Interview Button */}
  <div className="flex justify-center mt-4 space-x-2">
  {/* Join Interview Button */}
  <button
    onClick={() => handleJoinInterview(mentor.id)}
    className="w-1/2 py-2 border border-purple-600 rounded-md text-sm text-purple-600 hover:bg-purple-100 transition"
  >
    <i className="pi pi-user-plus mr-2"></i> Attend 
  </button>

  {/* Schedule Interview Button with Time Icon */}
  <button
    onClick={() => handleScheduleInterview(mentor.id)}
    className="w-1/2 p-3 px-3 bg-purple-600 text-white text-sm rounded-md hover:bg-purple-400 transition"
  >
    <i className="pi pi-clock mr-2"></i> Re-schedule 
  </button>
</div>

</div>

    
  ))}
</div>

<div className="mt-10">
  <div className="flex items-center justify-between">
    <h2 className="text-2xl font-bold mb-6 text-left">Past Interviews</h2>
    <a href="#" className="text-blue-600 hover:underline text-sm">See All</a>
  </div>

  <div className="overflow-x-auto bg-white dark:bg-slate-900 rounded-lg shadow-sm">
    <table className="min-w-full text-left border-collapse dark:border-gray-800">
      <thead>
        <tr className="border-b text-gray-600 dark:text-gray-400">
          <th className="p-4 font-semibold">Name</th>
          <th className="p-4 font-semibold">Position</th>
          <th className="p-4 font-semibold">Topic</th>
          <th className="p-4 font-semibold">Date</th>
          
          <th className="p-4 font-semibold">Rating</th>
          <th className="p-4 font-semibold">Feedback</th>
          <th className="p-4 font-semibold">Result</th>
        </tr>
      </thead>
      <tbody className="text-gray-900 dark:text-white">
        {meetings.map((meeting) => (
          <tr key={meeting.id} className="border-b dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-slate-800">
            <td className="p-4 flex items-center gap-2">
              <img
                src={meeting.avatar}
                alt={meeting.name}
                className="w-10 h-10 rounded-full object-cover"
              />
              <span>{meeting.name}</span>
            </td>
            <td className="p-4">{meeting.position}</td>
            <td className="p-4 text-sm">{meeting.topic}</td>
            <td className="p-4 text-sm">{meeting.date}</td>
            
            
            <td className="p-4 text-sm">{meeting.feedback}</td>
            <td className="p-4">
              <span className={`px-2 py-1 rounded-full text-xs font-semibold ${meeting.status === "Good" ? "bg-green-100 text-green-700" :
                meeting.status === "Improve" ? "bg-red-100 text-red-700" :
                  "bg-gray-100 text-gray-700"
              }`}>
                {meeting.status}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>



            </div>
        </>

    );
};

export default MockInt;
