// Data.jsx
import React from 'react';
import {

    FaBriefcase,
  
    FaFacebook,
    FaLinkedin,
    FaTwitter,
  
    FaHome,
    FaComment,
    FaBookOpen,
   
    FaBook,
    FaPaperPlane,
    FaUser,
    FaAtom,
    FaBuilding,
    FaUserCog,
    FaInstagram,
    FaYoutube,

} from 'react-icons/fa';
import { MdAccountCircle } from 'react-icons/md';

export const links = [
    {
        id: 1,
        url: '/dashboard',
        text: 'Home',
        icon: <FaHome className="w-5 h-5" />,
    },
    {
        id: 2,
        url: '/courses',
        text: 'Courses',
        icon: <FaAtom className="w-5 h-5" />,
    },
    {
        id: 3,
        url: '/career',
        text: 'Career',
        icon: <FaBriefcase className="w-5 h-5" />,
    },
    {
        id: 4,
        url: '/profile',
        text: 'Profile',
        icon: <FaUser className="w-5 h-5" />,
    },
    
   
    {
        id: 5,
        url: '/lms',
        text: 'LMS',
        icon: <FaBookOpen className="w-5 h-5" />,
    },
    {
        id: 6,
        url: '/mock-interview',
        text: 'Mock Interview',
        icon: <FaBook className="w-5 h-5" />,
    },
    {
        id: 7,
        url: '/test-papers',
        text: 'Test Paper',
        icon: <FaPaperPlane className="w-5 h-5 " />,
    },
    {
        id: 8,
        url: '/messages',
        text: 'Messages',
        icon: <FaComment className="w-5 h-5" />,
    },
    {
        id: 9,
        url: '/company-profile',
        text: 'Company',
        icon: <FaBuilding className="w-5 h-5 " />,
    },
    {
        id: 10,
        url: '/mentorship',
        text: 'Mentorship',
        icon: <FaUserCog className="w-5 h-5" />,
    },
    {
        id: 11,
        url: '/admin-dashboard',
        text: 'Admin',
        icon: <MdAccountCircle className="w-5 h-5" />,
    },
    
];


export const social = [
    {
        id: 1,
        url: 'https://www.facebook.com',
        icon: <FaFacebook />,
    },
    {
        id: 2,
        url: 'https://www.twitter.com',
        icon: <FaTwitter />,
    },
    {
        id: 3,
        url: 'https://www.linkedin.com',
        icon: <FaLinkedin />,
    },
    {
        id: 4,
        url: 'https://www.behance.net',
        icon: <FaInstagram />,
    },
    {
        id: 5,
        url: 'https://www.sketch.com',
        icon: <FaYoutube />,
    },
];
