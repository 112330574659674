import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import './App.css';
 // Ensure this path is correct
import HomePage from './pages/Homepage';
import Header from './components/Header';
import Footer from './components/Footer';
import Register from './pages/RegisterPage';
import Login from './pages/LoginPage';
import NotFound from './pages/404';
import Dashboard from './components/Dashboard';
import Courses1 from './components/Courses1';

import MessagingDashboard from './components/Messageingdashboard';
import Lms from './components/Lms';
import Vedio1 from './components/Vedio1';
import Profile from './components/Profile';
import Admin from './components/Admin';
import CompanyProfile from './components/CompanyProfile';
import Mentor from './components/Mentor';
import ProtectedRoute from './ProtectedRoute';
import { retrieveUserFromLocalStorage,  } from './App/Redux/RAuth.js/Actions';
import { useDispatch, useSelector } from 'react-redux';
import JobDashbaord from './components/JobDashbaord';
import Mock from './components/Mock';
import Test from './components/Test';
import Paper1 from './components/Paper1';
import Terms from './pages/Terms';
import Pp from './pages/Pp';
import ForgotPassword from './pages/ForgotPasswordModal';
import DeactivationPage from './pages/DeactivationPage';

// Protected Route Component

function Main() {
    const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  // UseEffect only runs on mount, so you only fetch the token once
  useEffect(() => {
    // Ensure the dispatch only happens once when the component is mounted
    dispatch(retrieveUserFromLocalStorage());
  }, [dispatch]); // Empty dependency array ensures this runs only once

  console.log(token, 'Token from Redux State');
  return (
    <BrowserRouter>
      <div className="App flex flex-col min-h-screen">
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <>
                <Header />
                <HomePage />
                <Footer />
              </>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <>
                <Header />
                <ForgotPassword />
                <Footer />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <Header />
                <Register />
                <Footer />
              </>
            }
          />
          <Route
            path="/tc"
            element={
              <>
                <Header />
                <Terms />
                <Footer />
              </>
            }
          />
          <Route
            path="/pp"
            element={
              <>
                <Header />
                <Pp />
                <Footer />
              </>
            }
          />
           <Route
            path="/deactivation"
            element={
              <>
                <Header />
                <DeactivationPage />
                <Footer />
              </>
            }
          />
           <Route
            path="*"
            element={
              <>
                
                <NotFound />
              
              </>
            }
          />
<Route
  path="/login"
  element={token ? (
    <Navigate to="/dashboard"  />
  ) : (
    <>
      <Header />
      <Login />
      <Footer />
    </>
  )}
/>

         

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute token={token}>
                  <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/courses"
            element={
              <ProtectedRoute token={token}>
                <Courses1 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/career"
            element={
              <ProtectedRoute token={token}>
                <JobDashbaord />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <ProtectedRoute token={token}>
                <MessagingDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lms"
            element={
              <ProtectedRoute token={token}>
                <Lms />
              </ProtectedRoute>
            }
          />
          <Route
            path="/videos"
            element={
              <ProtectedRoute token={token}>
                <Vedio1 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute token={token}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute token={token}>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/company-profile"
            element={
              <ProtectedRoute token={token}>
                <CompanyProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mentorship"
            element={
              <ProtectedRoute token={token}>
                <Mentor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mock-interview"
            element={
              <ProtectedRoute token={token}>
                <Mock />
              </ProtectedRoute>
            }
          />
          <Route
            path="/test-papers"
            element={
              <ProtectedRoute token={token}>
                <Test />
              </ProtectedRoute>
            }
          />
          <Route
            path="/live-papers"
            element={
              <ProtectedRoute token={token}>
                <Paper1 />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default Main;
