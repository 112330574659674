import React from 'react';
import Sidebar from './Sidebar';
import Dheader from './Dheader';
import Paper from '../pages/Paper';

const Paper1 = () => {
    return (
        <div className="dashboard-container flex">
            <Sidebar /> {/* Render Sidebar here */}
            <div className="flex-1 p-6">
                {/* Header starts after the sidebar */}
                <div className="md:ml-64"> {/* Adjust the margin based on the sidebar's width */}
                    <Dheader />
                </div>
                <div className="md:ml-64">
                    <Paper />
                </div>
            </div>
        </div>
    );
};

export default Paper1;
