import React from 'react';
import { Search, Edit2, Phone, Video, Clock } from 'lucide-react';

const MessagingDashboard = () => {
    return (
        <>
            <div className="mb-6">

                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>Message </span>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row h-screen bg-white dark:bg-slate-900 text-gray-800 dark:text-gray-100">
                {/* Left Sidebar */}
                <div className="w-full lg:w-80 bg-white border-b lg:border-b-0 lg:border-r">
                    {/* Header */}
                    <div className="p-4 border-b bg-white dark:bg-slate-950 text-gray-800 dark:text-gray-100">
                        <div className="flex items-center justify-between mb-4">
                            <h1 className="text-xl font-semibold text-purple-600">Messages</h1>
                            <div className="flex gap-2">
                                <button className="p-2 hover:bg-gray-100 rounded-full">
                                    <Edit2 className="w-5 h-5 text-gray-600" />
                                </button>
                                <button className="p-2 hover:bg-gray-100 rounded-full">
                                    <Search className="w-5 h-5 text-gray-600" />
                                </button>
                            </div>
                        </div>

                        {/* Pinned Message Section */}
                        <div className="mb-4">
                            <p className="text-sm text-gray-500 mb-2 text-left">📌 Pinned Message</p>
                            <div className="flex items-center space-x-3 p-2 hover:bg-gray-100  dark:hover:bg-gray-800 rounded-lg  bg-gray-100 dark:bg-slate-900 text-gray-800 dark:text-gray-100 ">
                                <div className="w-12 h-12 bg-orange-500 rounded-full flex items-center justify-center">
                                    <span className="text-white font-semibold">OS</span>
                                </div>
                                <div className="flex-1">
                                    <div className="flex justify-between">
                                        <h3 className="font-medium">Student Council</h3>
                                        <span className="text-xs text-gray-500">05:11 PM</span>
                                    </div>
                                    <p className="text-sm text-gray-500 truncate text-left">Meeting tomorrow at 3 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Conversations List */} <p className="text-sm text-gray-500 px-4 py-2 text-left">All Messages</p>
                    <div className="overflow-y-auto h-[calc(100vh-180px)] bg-white dark:bg-slate-900 text-gray-800 dark:text-gray-100">

                        {[1, 2, 3, 4, 5].map((_, index) => (
                            <div
                                key={index}
                                className={`flex items-center space-x-3 p-4  bg-gray-100 dark:bg-slate-900 text-gray-800 dark:text-gray-100 hover:bg-gray-100  dark:hover:bg-gray-800 cursor-pointer ${index === 0 ? 'bg-blue-50' : ''}`}
                            >
                                <div className="">
                                    <div className="w-12 h-12 bg-gray-300 rounded-full overflow-hidden">
                                        <img src={`https://images.pexels.com/photos/96920/pexels-photo-96920.jpeg?auto=compress&cs=tinysrgb&w=400`} alt="avatar" className="w-full h-full object-cover" />
                                    </div>
                                    
                                </div>
                                <div className="flex-1">
                                    <div className="flex justify-between">
                                        <h3 className="font-medium">Student {index + 1}</h3>
                                        <span className="text-xs text-gray-500">Just now</span>
                                    </div>
                                    <p className="text-sm text-gray-500 truncate text-left">Latest message preview...</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Main Chat Area */}
                <div className="flex-1 flex flex-col ">
                    {/* Chat Header */}
                    <div className="h-16 border-b bg-white px-4 flex items-center justify-between bg-gray-100 dark:bg-slate-900 text-gray-800 dark:text-gray-100 rounded-lg">
                        <div className="flex items-center space-x-3">
                            <div className="w-10 h-10 bg-gray-200 rounded-full overflow-hidden">
                                <img src={`https://images.pexels.com/photos/96920/pexels-photo-96920.jpeg?auto=compress&cs=tinysrgb&w=400`} alt="avatar" className="w-full h-full object-cover" />
                            </div>
                            <div>
                                <h2 className="font-medium">Current Chat</h2>
                                <p className="text-xs text-gray-500">Active now</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <button className="p-2 hover:bg-gray-100 rounded-full">
                                <Phone className="w-5 h-5 text-gray-600" />
                            </button>
                            <button className="p-2 hover:bg-gray-100 rounded-full">
                                <Video className="w-5 h-5 text-gray-600" />
                            </button>
                            <button className="p-2 hover:bg-gray-100 rounded-full">
                                <Clock className="w-5 h-5 text-gray-600" />
                            </button>
                        </div>
                    </div>

                    {/* Chat Messages */}
                    <div className="flex-1 overflow-y-auto p-4 space-y-4">
                        {/* Message bubbles */}
                        <div className="flex items-end gap-2 ">
                            <div className="w-8 h-8 bg-gray-200 rounded-full overflow-hidden">
                                <img src={`https://images.pexels.com/photos/96920/pexels-photo-96920.jpeg?auto=compress&cs=tinysrgb&w=400`} alt="avatar" className="w-full h-full object-cover" />
                            </div>
                            <div className="max-w-[70%] bg-gray-100 dark:bg-slate-950 text-gray-800 dark:text-gray-100 rounded-2xl rounded-bl-none p-3">
                                <p className="text-sm">Hi! How can I help you with your assignment?</p>
                                <span className="text-xs text-gray-500 mt-1">05:00 PM</span>
                            </div>
                        </div>

                        {/* User message */}
                        <div className="flex items-end justify-end gap-2">
                            <div className="max-w-[70%] bg-purple-600 text-white rounded-2xl rounded-br-none p-3">
                                <p className="text-sm">I need help with the math homework.</p>
                                <span className="text-xs text-blue-100 mt-1 text-left">05:02 PM</span>
                            </div>
                            <div className="w-8 h-8 bg-gray-200 rounded-full overflow-hidden">
                                <img src={`https://images.pexels.com/photos/96920/pexels-photo-96920.jpeg?auto=compress&cs=tinysrgb&w=400`} alt="user avatar" className="w-full h-full object-cover" />
                            </div>
                        </div>
                    </div>

                    {/* Message Input */}
                    <div className="h-20 border-t bg-gray-100 dark:bg-slate-900 text-gray-800 dark:text-gray-100 p-4">
                        <div className="flex items-center gap-4 bg-gray-100 rounded-full px-4 py-2">
                            <input
                                type="text"
                                placeholder="Type a message..."
                                className="flex-1 bg-transparent outline-none text-sm"
                            />
                            <button className="text-blue-600 font-semibold text-sm">Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MessagingDashboard;
