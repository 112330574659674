import React, { useState } from 'react';
import { MapPin, Search, } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
const TestPapers = () => {
    const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/live-papers'); // Navigates to the "live-papers" page
  };

    const [activeTab, setActiveTab] = useState("recommended");
    const [subTab, setSubTab] = useState("allTests");
    const recommendedTests={
        allTests: [
            {
                id: 1,
                name: "React Fundamentals",
                topic: "Introduction to React",
                questions: 50,
                minutes: 60,
                marks: 100,
                date: "2025-02-01",
                startTime: "10:00 AM",
                endTime: "11:00 AM",
                isFree: true,
                status:"Start Now",
              },
              {
                id: 2,
                name: "JavaScript Mastery",
                topic: "Advanced JavaScript Topics",
                questions: 40,
                minutes: 45,
                marks: 80,
                date: "2025-02-02",
                startTime: "11:00 AM",
                endTime: "11:45 AM",
                isFree: false,
                status:"Register",
              },
              {
                id: 3,
                name: "CSS Styling",
                topic: "Responsive Design Techniques",
                questions: 30,
                minutes: 40,
                marks: 70,
                date: "2025-02-03",
                startTime: "9:00 AM",
                endTime: "9:40 AM",
                isFree: true,
                status:"Start Now",
              },
              {
                id: 4,
                name: "Node.js Basics",
                topic: "Backend Development with Node.js",
                questions: 35,
                minutes: 50,
                marks: 90,
                date: "2025-02-04",
                startTime: "12:00 PM",
                endTime: "12:50 PM",
                isFree: true,
                status:"Register",
              },
              {
                id: 5,
                name: "DevOps Practices",
                topic: "Continuous Integration and Deployment",
                questions: 40,
                minutes: 60,
                marks: 100,
                date: "2025-02-05",
                startTime: "2:00 PM",
                endTime: "3:00 PM",
                isFree: false,
                status:"Register",
              },
              {
                id: 6,
                name: "Database Design",
                topic: "Relational Database Management Systems",
                questions: 20,
                minutes: 30,
                marks: 50,
                date: "2025-02-06",
                startTime: "3:00 PM",
                endTime: "3:30 PM",
                isFree: true,
                status:"Start Now",
              },
              {
                id: 7,
                name: "React Native Essentials",
                topic: "Building Mobile Apps with React Native",
                questions: 25,
                minutes: 35,
                marks: 60,
                date: "2025-02-07",
                startTime: "4:00 PM",
                endTime: "4:35 PM",
                isFree: true,
                status:"Register",
              },
              {
                id: 8,
                name: "API Integration",
                topic: "Working with REST and GraphQL APIs",
                questions: 30,
                minutes: 40,
                marks: 70,
                date: "2025-02-08",
                startTime: "5:00 PM",
                endTime: "5:40 PM",
                isFree: false,
                status:"Register",
              },
              {
                id: 9,
                name: "Python Basics",
                topic: "Introduction to Python Programming",
                questions: 50,
                minutes: 60,
                marks: 100,
                date: "2025-02-09",
                startTime: "6:00 PM",
                endTime: "7:00 PM",
                isFree: true,
                status:"Start Now",
              },
              {
                id: 10,
                name: "Cloud Computing",
                topic: "Overview of Cloud Platforms",
                questions: 20,
                minutes: 25,
                marks: 50,
                date: "2025-02-10",
                startTime: "7:00 PM",
                endTime: "7:25 PM",
                isFree: false,
                status:"Register",
              },
        ],
      };
    const tabContent = recommendedTests[subTab] || [];
   
      
   


    return (
        <>
            <div className="mb-6">

                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>Test Papers</span>
                </div>
            </div>

            <div className="min-h-screen bg-white dark:bg-slate-950 text-slate-900 dark:text-white">
                {/* Hero Section */}
                <div className="max-w-5xl mx-auto text-center px-8 py-12 bg-purple-600 rounded-lg shadow-2xl relative">
                    <h3 className="text-4xl font-semibold text-white mb-8 tracking-wide">Live Tests Ppaers & Free Quizzes</h3>

                    <div className="flex bg-white dark:bg-slate-900 rounded-full shadow-md overflow-hidden ring-2 ring-gray-200 dark:ring-slate-700 transition-all">
                        {/* Job Title Input */}
                        <div className="flex items-center px-6 w-1/2 border-r border-gray-200 dark:border-slate-700">
                            <Search className="w-6 h-6 text-gray-400 dark:text-slate-500 mr-3" />
                            <input
                                type="text"
                                placeholder="paper name "
                                className="w-full bg-transparent placeholder-gray-500 dark:placeholder-slate-500 text-gray-700 dark:text-gray-300 focus:outline-none focus:ring-0 focus:text-indigo-600 dark:focus:text-indigo-300 transition"
                            />
                        </div>

                        {/* Location Input */}
                        <div className="flex items-center px-6 w-1/2 border-r border-gray-200 dark:border-slate-700">
                            <MapPin className="w-6 h-6 text-gray-400 dark:text-slate-500 mr-3" />
                            <input
                                type="text"
                                placeholder="category"
                                className="w-full bg-transparent placeholder-gray-500 dark:placeholder-slate-500 text-gray-700 dark:text-gray-300 focus:outline-none focus:ring-0 focus:text-indigo-600 dark:focus:text-indigo-300 transition"
                            />
                        </div>

                        {/* Search Button */}
                        <button className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-8 py-2 font-semibold rounded-full hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 transform hover:scale-105">
                            Search
                        </button>
                    </div>
                </div>


                {/* Main Content */}
                <div className="max-w-6xl mx-auto py-10 px-6">
                    

                    <div className="p-6 min-h-screen">
      {/* Main Tabs */}
      <div className="flex justify-left mb-8 gap-6">
        <button
          onClick={() => setActiveTab("recommended")}
          className={`px-4 py-2 rounded-lg text-md font-medium transition ${
            activeTab === "recommended"
              ? "bg-purple-500 text-white shadow-lg"
              : "bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
          }`}
        >
          Recommended Tests
        </button>
        <button
          onClick={() => setActiveTab("attended")}
          className={`px-4 py-2 rounded-lg text-md font-medium transition ${
            activeTab === "attended"
              ? "bg-purple-500 text-white shadow-lg"
              : "bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
          }`}
        >
          Attended
        </button>
      </div>

      {activeTab === "recommended" && (
        <>
          {/* Sub Tabs */}
          <div className="flex justify-left mb-8 gap-6">
            <button
              onClick={() => setSubTab("allTests")}
              className={`px-3 py-1 rounded-lg text-md rounded-lg font-medium transition ${
                subTab === "allTests"
                  ? "bg-purple-400 text-white"
                  : "bg-gray-300 dark:bg-gray-800 text-gray-700 dark:text-gray-300"
              }`}
            >
              All Tests
            </button>
            <button
              onClick={() => setSubTab("quizzes")}
              className={`px-4 py-2 rounded-lg font-medium transition ${
                subTab === "quizzes"
                  ? " bg-purple-400 text-white text-white"
                  : "bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300"
              }`}
            >
              Quizzes
            </button>
          </div>

          {/* Test Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
  {tabContent
    .filter((test) => subTab === "allTests" || test.name.toLowerCase().includes("quiz"))
    .map((test) => (
      <div
        key={test.id}
        className="bg-gray-100 dark:bg-gray-900  transition-transform hover:scale-105 hover:shadow-2xl hover:shadow-purple-500 p-5"
      >
        <div className="flex justify-between items-center mb-4">
          <h5 className="font-semibold text-lg text-purple-800 dark:text-white">{test.name}</h5>
          <div className="flex items-center">
            {test.isFree && (
              <span className="text-sm text-green-600 font-bold border border-green-600 rounded-md px-2 py-0.5 ml-2">
                Free
              </span>
            )}
          </div>
        </div>

        <p className="text-gray-800 dark:text-white font-medium mb-3">{test.topic || "No topic provided"}</p>

        <div className="text-sm text-gray-600 dark:text-gray-400 mb-4">
          Marks: {test.marks} | Minutes: {test.minutes} | Questions: {test.questions}
        </div>

        <div className="flex items-center text-sm text-gray-600 dark:text-gray-400 mb-4">
  {/* <Clock className="w-3 h-3 text-1xl mr-2" /> */}
  <span>{test.date} from {test.startTime} to {test.endTime}</span>
</div>


<button
        className="w-full border border-purple-400 text-purple-600 font-medium rounded-lg py-1 px-3 transition hover:bg-purple-900 hover:text-white"
        onClick={handleButtonClick}
      >
        {test.status}
      </button>
      </div>
    ))}
</div>

        </>
      )}

      {activeTab === "attended" && (
        <div className="text-center text-gray-500 dark:text-gray-400 mt-6">
          <p>No attended tests available at the moment.</p>
        </div>
      )}
    </div>
    </div>
    </div>
        </>

    );
};

export default TestPapers;
