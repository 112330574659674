import { useState } from "react";

const TermsAndConditions = () => {
  const [showFull, setShowFull] = useState(false);

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white shadow-lg rounded-xl border border-gray-200 dark:bg-slate-800 dark:text-white">
      <h2 className="text-2xl font-bold text-gray-800 mb-4 dark:text-white">Terms and Conditions</h2>
      <p className="text-gray-600 mb-4 dark:bg-slate-800 dark:text-white">
      Thank you for choosing <span className="font-semibold">Collegmate Community</span>. By accessing and using our platform,you agree to comply with the following terms and conditions, ensuring a safe, respectful, and engaging environment for all members.
      </p>
      
      <div className="text-gray-700 space-y-3">
  <ul className="list-disc pl-5 space-y-4 dark:bg-slate-800 dark:text-white">
    <li><span className="font-semibold">Community Conduct:</span> Be respectful, avoid offensive content, and foster a positive environment.</li>
    <li><span className="font-semibold">Content Usage:</span> Your contributions remain yours, but we may showcase them for community benefit.</li>
    <li><span className="font-semibold">Privacy:</span> We respect your data. Review our Privacy Policy for details.</li>
    <li><span className="font-semibold">No Spam:</span> Avoid posting spam, advertisements, or irrelevant content.</li>
    <li><span className="font-semibold">Termination:</span> Violating our terms may result in suspension or removal.</li>
    <li><span className="font-semibold">Modifications:</span> We reserve the right to update these terms as needed.</li>
    <li><span className="font-semibold">Intellectual Property:</span> Respect copyrights, trademarks, and other intellectual property rights.</li>
    {showFull && (
      <div>
        <ul className="list-disc pl-5">
          <li><span className="font-semibold">Security:</span> Keep your account secure and report any suspicious activity.</li>
          <li><span className="font-semibold">Liability:</span> We are not responsible for user-generated content or third-party actions.</li>
          <li><span className="font-semibold">Dispute Resolution:</span> Any disputes will be resolved through arbitration or applicable legal means.</li>
        </ul>
      </div>
    )}
  </ul>
</div>

      
      <button 
        onClick={() => setShowFull(!showFull)}
        className="mt-4 text-blue-600 hover:underline focus:outline-none"
      >
        {showFull ? "Show Less" : "Show More"}
      </button>
    </div>
  );
};

export default TermsAndConditions;
