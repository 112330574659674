import axiosInstance from "./axios";

const authApi = {
  registerUser: async (userData) => {
    try {
      if (!userData) {
        console.error('Error: userData is missing or invalid');
        return { success: false, error: 'User data is missing or invalid' };
      }
  
      const formData = new FormData();
  
      // If user has uploaded a profile image, append it
      if (userData.profileImage) {
        const file = userData.profileImage;  // Should be a file input element or URI of the file
        formData.append('image', file);
      } else {
        console.log('Images information is missing or invalid');
      }
  
      // Append the form data (username, email, password, etc.)
      formData.append('username', userData.username || '');
      formData.append('email', userData.email || '');
      formData.append('password', userData.password || '');
  
      // Append the college name (collegeID as string) and the college ObjectId (college as string)
      formData.append('collegeID', userData.collegeID || '');  // College name
      formData.append('college', userData.college || '');  // College ObjectId (MongoDB ObjectId)
  
      console.log(userData);  // Debugging log to ensure userData is as expected
      
      // Send the data to the backend
      const response = await axiosInstance.post('/register/image', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response) {
        return response.data;
      }
    } catch (error) {
      console.error('Error during registration:', error.message);
      return { success: false, error: 'An unexpected error occurred' };
    }
  },
  

 login: async (email, password, NotificationToken) => {
    try {
      const response = await axiosInstance.post('/user/login', {
        email,
        password,
        NotificationToken,
      });
console.log(response,'login1')
      if (response.status !== 200) {
        throw new Error(response.data.message || 'Login failed');
      }

      const data = response.data;

      return data;
    } catch (error) {
      console.error('Login failed:', error.message);
      throw error;
    }
  },

  handleForgotPassword: async (email) => {
    try {
      const response = await axiosInstance.post('/forgot-password', { email });
      
      console.log('authpage response:', response);  // Log the full response for debugging
  
      // Check if the response status is OK (2xx)
      if (response.status < 200 || response.status >= 300) {
        throw new Error('Network response was not ok');
      }
  
      const data = response.data;  // Get the response data from the Axios response
  
      console.log('authApi:', data);  // Log the actual response data
      
      if (data.message === 'Password reset email sent successfully') {
        alert('Please check your email for password reset instructions.');
      } else {
        alert(data.message); // Display error if any
      }
    } catch (error) {
      console.error('Error during password reset:', error); // Log the error
      alert('Something went wrong. Please try again later.');
    }
  },  
  fetchResetPage: async (userId) => {
    try {
      const response = await axiosInstance.get(`/reset-password/${userId}`);
      const data = await response.text(); // Expecting HTML for the reset page
  
      // Inject the fetched HTML into the reset password container
      document.getElementById('reset-password-container').innerHTML = data;
    } catch (error) {
      console.error('Error fetching reset password page:', error);
      alert('Could not fetch the reset page.');
    }
  },

  handleResetPassword: async (userId, password, confirmPassword) => {
  try {
    const response = await axiosInstance.post(`/reset-password/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password, confirmPassword }),
    });

    // Check if the response is OK (2xx)
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.status === 400) {
      alert(data.message); // Passwords do not match
    } else if (data.status === 404) {
      alert('User not found');
    } else if (data.status === 500) {
      alert('An error occurred, please try again.');
    } else {
      alert('Password successfully updated!');
      window.location.href = '/login'; // Redirect after successful password change
    }
  } catch (error) {
    console.error('Error during password reset:', error);
    alert('Something went wrong. Please try again later.');
  }
},
  
};

export default authApi;
