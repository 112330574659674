import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Paper = () => {
    const test = {
        name: 'IT Knowledge Test',
        minutes: 60, // Set the test duration in minutes
        questions: [
          // Set 1
          {
            id: 1,
            text: 'What does HTML stand for?',
            options: ['HyperText Markup Language', 'HyperText Machine Language', 'HighText Markup Language', 'None of the above'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'HyperText Markup Language'
          },
          {
            id: 2,
            text: 'Which of the following is a backend programming language?',
            options: ['JavaScript', 'PHP', 'HTML', 'CSS'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'PHP'
          },
          {
            id: 3,
            text: 'Which protocol is used for secure communication over a computer network?',
            options: ['HTTP', 'HTTPS', 'FTP', 'TCP'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'HTTPS'
          },
      
          // Set 2
          {
            id: 4,
            text: 'What is the full form of CSS in web development?',
            options: ['Cascading Style Sheets', 'Cascading Style System', 'Computer Style Sheets', 'None of the above'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Cascading Style Sheets'
          },
          {
            id: 5,
            text: 'Which programming language is primarily used for mobile app development in Android?',
            options: ['Java', 'C++', 'Python', 'Swift'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Java'
          },
          {
            id: 6,
            text: 'What does the acronym "URL" stand for?',
            options: ['Uniform Resource Locator', 'Uniform Resource Link', 'Unified Resource Locator', 'Universal Resource Locator'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Uniform Resource Locator'
          },
      
          // Set 3
          {
            id: 7,
            text: 'What does API stand for?',
            options: ['Application Programming Interface', 'Application Program Interface', 'Active Programming Interface', 'None of the above'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Application Programming Interface'
          },
          {
            id: 8,
            text: 'What is the main purpose of a firewall in a computer network?',
            options: ['Block viruses', 'Monitor network traffic', 'Prevent unauthorized access', 'Increase network speed'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Prevent unauthorized access'
          },
          {
            id: 9,
            text: 'Which of the following is a version control system?',
            options: ['Git', 'Python', 'Java', 'HTML'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Git'
          },
      
          // Set 4
          {
            id: 10,
            text: 'What does the acronym "SQL" stand for?',
            options: ['Structured Query Language', 'Simple Query Language', 'Synchronized Query Language', 'None of the above'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Structured Query Language'
          },
          {
            id: 11,
            text: 'What is the name of the operating system used in most Apple devices?',
            options: ['Linux', 'macOS', 'Windows', 'Android'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'macOS'
          },
          {
            id: 12,
            text: 'Which programming language is often used for machine learning and artificial intelligence?',
            options: ['Java', 'C++', 'Python', 'JavaScript'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Python'
          },
      
          // Set 5
          {
            id: 13,
            text: 'What is the full form of "HTTP"?',
            options: ['HyperText Transfer Protocol', 'HyperText Transmission Protocol', 'HyperText Transfer Package', 'HyperText Transfer Process'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'HyperText Transfer Protocol'
          },
          {
            id: 14,
            text: 'Which of the following is an example of a cloud computing platform?',
            options: ['AWS', 'Linux', 'Python', 'None of the above'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'AWS'
          },
          {
            id: 15,
            text: 'What is the name of the version of JavaScript that compiles to native code for mobile devices?',
            options: ['Node.js', 'React Native', 'Swift', 'Android JavaScript'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'React Native'
          },
      
          // Set 6
          {
            id: 16,
            text: 'Which HTML tag is used to display an image?',
            options: ['<img>', '<image>', '<src>', '<picture>'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: '<img>'
          },
          {
            id: 17,
            text: 'Which of the following is a front-end JavaScript framework?',
            options: ['Django', 'React', 'Flask', 'Laravel'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'React'
          },
          {
            id: 18,
            text: 'Which tool is used for continuous integration and continuous delivery (CI/CD)?',
            options: ['GitHub', 'Jenkins', 'Docker', 'Visual Studio Code'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Jenkins'
          },
      
          // Set 7
          {
            id: 19,
            text: 'Which of the following is the standard protocol used for email communication?',
            options: ['SMTP', 'HTTP', 'FTP', 'SSH'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'SMTP'
          },
          {
            id: 20,
            text: 'Which term refers to the process of making a website or app accessible from any device?',
            options: ['Responsive design', 'Adaptive design', 'Fixed design', 'Static design'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Responsive design'
          },
          {
            id: 16,
            text: 'Which HTML tag is used to display an image?',
            options: ['<img>', '<image>', '<src>', '<picture>'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: '<img>'
          },
          {
            id: 17,
            text: 'Which of the following is a front-end JavaScript framework?',
            options: ['Django', 'React', 'Flask', 'Laravel'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'React'
          },
          {
            id: 18,
            text: 'Which tool is used for continuous integration and continuous delivery (CI/CD)?',
            options: ['GitHub', 'Jenkins', 'Docker', 'Visual Studio Code'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Jenkins'
          },
      
          // Set 7
          {
            id: 19,
            text: 'Which of the following is the standard protocol used for email communication?',
            options: ['SMTP', 'HTTP', 'FTP', 'SSH'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'SMTP'
          },
          {
            id: 20,
            text: 'Which term refers to the process of making a website or app accessible from any device?',
            options: ['Responsive design', 'Adaptive design', 'Fixed design', 'Static design'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Responsive design'
          },
          {
            id: 16,
            text: 'Which HTML tag is used to display an image?',
            options: ['<img>', '<image>', '<src>', '<picture>'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: '<img>'
          },
          {
            id: 17,
            text: 'Which of the following is a front-end JavaScript framework?',
            options: ['Django', 'React', 'Flask', 'Laravel'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'React'
          },
          {
            id: 18,
            text: 'Which tool is used for continuous integration and continuous delivery (CI/CD)?',
            options: ['GitHub', 'Jenkins', 'Docker', 'Visual Studio Code'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Jenkins'
          },
      
          // Set 7
          {
            id: 19,
            text: 'Which of the following is the standard protocol used for email communication?',
            options: ['SMTP', 'HTTP', 'FTP', 'SSH'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'SMTP'
          },
          {
            id: 20,
            text: 'Which term refers to the process of making a website or app accessible from any device?',
            options: ['Responsive design', 'Adaptive design', 'Fixed design', 'Static design'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Responsive design'
          },
          {
            id: 16,
            text: 'Which HTML tag is used to display an image?',
            options: ['<img>', '<image>', '<src>', '<picture>'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: '<img>'
          },
          {
            id: 17,
            text: 'Which of the following is a front-end JavaScript framework?',
            options: ['Django', 'React', 'Flask', 'Laravel'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'React'
          },
          {
            id: 18,
            text: 'Which tool is used for continuous integration and continuous delivery (CI/CD)?',
            options: ['GitHub', 'Jenkins', 'Docker', 'Visual Studio Code'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Jenkins'
          },
      
          // Set 7
          {
            id: 19,
            text: 'Which of the following is the standard protocol used for email communication?',
            options: ['SMTP', 'HTTP', 'FTP', 'SSH'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'SMTP'
          },
          {
            id: 20,
            text: 'Which term refers to the process of making a website or app accessible from any device?',
            options: ['Responsive design', 'Adaptive design', 'Fixed design', 'Static design'],
            selectedAnswer: null,
            status: 'Not Visited',
            correctAnswer: 'Responsive design'
          },
        ],
      };
      
   // Timer in seconds
    const [questions, setQuestions] = useState(test.questions);
    const [status, setStatus] = useState("Not Visited");
    const [timer, setTimer] = useState(0);
  const [isTiming, setIsTiming] = useState(false); // Timer state (whether it is started or not)

    const navigate = useNavigate();
    const handleTimeControl = () => {
        if (!isTiming) {
          setIsTiming(true);
        }
      };
    
      // Start the timer when the component mounts and when 'isTiming' is true
      useEffect(() => {
        let timerInterval;
        if (isTiming) {
          timerInterval = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
          }, 1000);
        }
    
        // Cleanup the interval when the component unmounts or when the timer is stopped
        return () => clearInterval(timerInterval);
      }, [isTiming]);
    
    const handleAnswerChange = (questionId, selectedOption) => {
      const updatedQuestions = questions.map((question) =>
        question.id === questionId
          ? { ...question, selectedAnswer: selectedOption, status: 'Answered' }
          : question
      );
      setQuestions(updatedQuestions);
    };
  
    const handleMarkChange = (questionId) => {
      const updatedQuestions = questions.map((question) =>
        question.id === questionId
          ? { ...question, status: question.status === 'Marked' ? 'Answered' : 'Marked' }
          : question
      );
      setQuestions(updatedQuestions);
    };
  
    const handleSubmit = () => {
      // Here you can handle test submission logic
      alert('Test submitted!');
    };
  
    return (
        <div className="p-6 min-h-screen">
        {/* Header with Total Marks and Timer */}
        <header className="flex justify-between items-center mb-8">
        <div className="mb-6">
                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className="text-purple-600">Live-testPapers</span>
                </div>
            </div>
        <div className="flex items-center space-x-6">
          <span className="text-sm text-gray-600 dark:text-gray-400">
            {`Time: ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`}
          </span>
          {/* Button to Start Timer */}
          <button
            onClick={handleTimeControl}
            className="bg-purple-600 text-white py-2 px-4 rounded-lg font-medium hover:bg-purple-700"
          >
            {isTiming ? 'Time Started' : 'Start Time'}
          </button>
        </div>
      </header>
      
        <div className="flex space-x-4">
          {/* Sidebar with Question Numbers and Status */}
          <div className="w-full sm:w-1/5 bg-gray-100 dark:bg-gray-900  p-4 space-y-4 mb-8 sm:mb-0">
            <h3 className="text-lg font-semibold text-purple-800 dark:text-white">Questions</h3>
            <div className="grid grid-cols-5 gap-2">
              {questions.map((question) => (
                <div key={question.id}>
                  <button
                    onClick={() => navigate(`/question/${question.id}`)}
                    className={`w-full text-center  font-sm ${
                      question.status === 'Answered'
                        ? 'bg-green-600 text-white'
                        : question.status === 'Marked'
                        ? 'bg-yellow-600 text-white'
                        : 'bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                    }`}
                  >
                    {question.id}
                  </button>
                </div>
              ))}
            </div>
      
            {/* Status Options */}
            <div className="mt-4">
              <h4 className="text-sm text-purple-800 dark:text-white">Status</h4>
              <div className="space-y-2 text-sm">
                <p>Answered: {questions.filter((q) => q.status === 'Answered').length}</p>
                <p>Marked: {questions.filter((q) => q.status === 'Marked').length}</p>
                <p>Not Visited: {questions.filter((q) => q.status === 'Not Visited').length}</p>
                <p>Not Answered: {questions.filter((q) => q.status === 'Not Answered').length}</p>
              </div>
            </div>
          </div>
      
          {/* Questions Section */}
          <div className="w-full sm:w-4/5">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6">
              {questions.map((question) => (
                <div key={question.id} className="bg-gray-100 dark:bg-gray-900  p-6 ">
                  <h4 className="text-lg font-semibold text-purple-800 dark:text-white">
                    {question.id}. {question.text}
                  </h4>
                  <div className="space-y-4">
                    {question.options.map((option) => (
                      <label key={option} className="block text-gray-600 dark:text-gray-400">
                        <input
                          type="radio"
                          name={`question-${question.id}`}
                          value={option}
                          checked={question.selectedAnswer === option}
                          onChange={() => handleAnswerChange(question.id, option)}
                          className="mr-2"
                        />
                        {option}
                      </label>
                    ))}
                  </div>
      
                  <div className="mt-4 flex justify-between items-center">
                    <button
                      onClick={() => handleMarkChange(question.id)}
                      className="text-sm font-medium text-yellow-600 hover:text-yellow-700"
                    >
                      {question.status === 'Marked' ? 'Unmark' : 'Mark'}
                    </button>
      
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      Status: <span className={`font-bold ${question.status === 'Answered' ? 'text-green-600' : question.status === 'Marked' ? 'text-yellow-600' : 'text-gray-500'}`}>{question.status}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      
        {/* Footer with Submit */}
        <footer className="mt-8 flex justify-between items-center">
          <div className="text-sm text-gray-600 dark:text-gray-400">
            <p>
              Answered: {questions.filter((q) => q.status === 'Answered').length} | Marked: {questions.filter((q) => q.status === 'Marked').length} | Not Visited: {questions.filter((q) => q.status === 'Not Visited').length}
            </p>
          </div>
          <button
            onClick={handleSubmit}
            className="bg-purple-600 text-white py-2 px-6 rounded-lg font-medium hover:bg-purple-700"
          >
            Submit Test
          </button>
        </footer>
      </div>
      
    
    );
  };
  
  export default Paper;
  