import { useState } from "react";
import { requestDeactivation } from "../App/API/collegeAuth";

const DeactivationPage = () => {
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await requestDeactivation(email, reason);
      setMessage(response.message);
      setSubmitted(true);
    } catch (error) {
      setMessage("Failed to submit deactivation request. Please try again.");
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white border border-gray-200 dark:bg-slate-800 dark:text-white mt-10">
      {submitted ? (
        <div>
          <h2 className="text-3xl mb-4 text-green-600 font-semibold">
            Confirmation Link Sent to Email
          </h2>
          <p className="text-green-600 font-medium">{message}</p>
        </div>
      ) : (
        <>
          <h2 className="text-3xl mb-6">Account Deactivation</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block mb-1">Email Address</label>
              <input
                type="email"
                className="w-full p-2 border border-gray-300 rounded-md dark:bg-slate-700 dark:border-gray-600"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block mb-1">Reason for Deactivation</label>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md dark:bg-slate-700 dark:border-gray-600"
                placeholder="Enter your reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white font-semibold py-2 rounded-md hover:bg-cyan-500"
            >
              Submit Request
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default DeactivationPage;
