

import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { PanelMenu } from 'primereact/panelmenu';
import ThemeSwitcher from './ThemeSwitcher';

const Header = () => {
    const [visible, setVisible] = useState(false);

    const items = [

    ];

    const buttonStyle = `
    text-transparent bg-clip-text bg-gradient-to-r 
    from-cyan-400 via-purple-500 to-purple-600 
    hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
    focus:ring-purple-300 dark:focus:ring-purple-800 
    font-bold rounded-lg text-xl px-4 py-2  {/* Changed from text-lg to text-xl */}
    text-center me-2 mb-2
`;

    const signupButtonStyle = `
    text-transparent bg-clip-text bg-gradient-to-r 
    from-purple-400 via-purple-500 to-purple-600 
    hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
    focus:ring-cyan-300 dark:focus:ring-cyan-800 
    font-bold rounded-lg text-xl px-4 py-2  {/* Changed from text-lg to text-xl */}
    text-center me-2 mb-2
`;



    const start = (
        <a href="/" className="w-auto h-8 header-logo" />
    );

    const end = (
        <div className="flex items-center gap-2">
        <div className="items-center hidden gap-4 -ml-12 lg:flex">
            
            <button
                type="button"
                className="mr-3 font-bold text-purple-500 hover:text-cyan-500"
                onClick={() => window.location.href = '/login'}
            >
                Login
            </button>
            <button
                type="button"
                className="mr-3 font-bold text-purple-500 hover:text-cyan-500"
                onClick={() => window.location.href = '/signup'}
            >
                Register
            </button>
        </div>
    
        <a 
    href="https://whatsapp.com/channel/0029Vb1l0Tm6mYPOb9GJ8I09" 
    target="_blank" 
    rel="noopener noreferrer" 
    className="flex items-center justify-center px-2 py-1 mr-2 font-semibold text-white transition duration-300 bg-green-500 rounded-full shadow-lg hover:bg-green-600 shadow-green-500/55"
>
    <span className="lg:hidden">
        <i className="pi pi-whatsapp text-1xl"></i> {/* WhatsApp Icon for mobile view */}
    </span>
    <span className="hidden ml-2 lg:inline-block">
        Join Community {/* Full text for larger screens */}
    </span>
</a>

        
    
        <ThemeSwitcher />
    </div>
    
    );

    return (
        <header className="sticky top-0 z-50 w-full px-6 py-4 bg-white dark:bg-slate-950 text-slate-900 dark:text-white md:px-8 md:py-6 lg:px-12 lg:py-8">
            <div className="flex items-center justify-between">
                <div className="flex items-center">{start}</div>
                <div className="justify-end flex-grow hidden lg:flex">
                    <nav className="mt-2">
                        {items.map((item, index) => (
                            <a
                                key={index}
                                href={item.url}
                                className="px-4 py-2 bg-clip-text text-slate-900 dark:text-white hover:text-purple-700"
                            >
                                {item.label}
                            </a>
                        ))}
                    </nav>
                </div>

                <div className="flex items-center gap-2">
                    <div className="lg:hidden">
                        <Button
                            icon={<i className="text-gray-500 pi pi-bars dark:text-white"></i>}
                            onClick={() => setVisible(true)}
                            className="bg-transparent bg-white border-0 hover:bg-transparent dark:bg-slate-950"
                        />
                    </div>
                    {end}
                </div>
            </div>

            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                baseZIndex={1000}
                className='p-4 transition-transform duration-300 ease-in-out border-none rounded-lg shadow-lg dark:bg-slate-950'
            >
                <PanelMenu model={items} className="w-full md:w-80 lg:w-96" />
                <div className="p-4">
                    <div className="mb-4 header-logo"></div>

                    {/* Login and Register Buttons */}
                    <div className="flex flex-col mt-10 space-y-2 items-left">
                        <button
                            type="button"
                            className={`${buttonStyle}  flex items-center space-x-2 transition-colors duration-200 hover:bg-sky-600 hover:text-purple-400`}
                            onClick={() => window.location.href = '/login'}
                        >
                            <i className="pi pi-sign-in"></i>
                            <span>Login</span>
                        </button>
                        <button
                            type="button"
                            className={`${buttonStyle} flex items-center space-x-2 transition-colors duration-200 hover:bg-sky-600 hover:text-purple-400`}
                            onClick={() => window.location.href = '/signup'}
                        >
                            <i className="pi pi-user-plus"></i>
                            <span>Register</span>
                        </button>
                    </div>

                    {/* Contact Us Section */}
                    <div className="flex flex-col items-start mt-3"> {/* Align items to the start (left) */}
                        <h4 className={`${buttonStyle} text-2xl text-purple-500 flex items-center transition-colors duration-200 hover:bg-purple-600 hover:text-white mb-4 hover:text-purple-400`}>
                            <i className="mr-2 text-purple-500 pi pi-envelope text-1xl "></i> {/* Icon size adjusted */}
                            Contact Us
                        </h4>

                        <p className="mt-2 text-lg font-semibold text-purple-400 text-purple-800 bg-white dark:bg-slate-950"> {/* Changed text color to purple */}
                            <span className="block text-left">Address: Bengaluru, India</span>
                        </p>
                        <p className="mt-2 text-lg font-semibold text-left text-purple-400 text-purple-800 bg-white dark:bg-slate-950"> {/* Changed text color to purple */}
                            Email: <a href="mailto:support@collegemate.io" className="font-semibold text-purple-400 text-purple-800 bg-white dark:bg-slate-950 hover:underline">support@collegemate.io</a>
                        </p>
                        <p className="mt-2 text-lg font-semibold text-left text-purple-400 text-purple-800 bg-white dark:bg-slate-950"> {/* Changed text color to purple */}
                            Phone: <span className="font-semibold text-purple-400 text-purple-800 bg-white dark:bg-slate-950">+91-8105827558</span>
                        </p>

                        {/* Social Media Links */}
                        <div className="flex justify-start mt-4 space-x-5"> {/* Center social media icons */}
    <a href="https://www.instagram.com/collegemate.io" 
       className="text-white transition-colors duration-200 hover:text-pink-500" 
       aria-label="Instagram">
        <i className="text-2xl pi pi-instagram"></i>
    </a>
    
    <a href="https://www.linkedin.com/company/collegemateio/" 
       className="text-white transition-colors duration-200 hover:text-blue-500" 
       aria-label="LinkedIn">
        <i className="text-2xl pi pi-linkedin"></i>
    </a>
    
    <a href="https://wa.me/8105827558" 
       className="text-white transition-colors duration-200 hover:text-green-500" 
       aria-label="WhatsApp">
        <i className="text-2xl pi pi-whatsapp"></i>
    </a>
</div>

                    </div>
                </div>
            </Sidebar>

        </header>
    );
};

export default Header;
