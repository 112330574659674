import axiosInstance from "./axios";


export const fetchColleges = async () => {
  try {
    const response = await axiosInstance.get("/allcolleges");
    return response.data;
  } catch (error) {
    console.error("Error fetching colleges:", error);
    throw error;
  }
};

export const requestDeactivation = async (email, reason) => {
  try {
    const response = await axiosInstance.post("/api/request-deactivation", {
      email,
      reason,
    });

    return response.data; // No need to call response.json() with Axios
  } catch (error) {
    console.error("Error requesting deactivation:", error);
    throw new Error("Failed to request deactivation.");
  }
};

export const confirmDeactivation = async (email, reason) => {
  try {
    const response = await axiosInstance.get(
      "/api/deactivate-confirm?email=${encodeURIComponent(email)}&reason=${encodeURIComponent(reason)}",
      {
        method: "GET",
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error confirming deactivation:", error);
    throw new Error("Failed to confirm deactivation.");
  }
};