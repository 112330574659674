import { useState } from "react";

const Pp = () => {
  return (
    <div className="max-w-7xl mx-auto p-6 bg-white shadow-lg rounded-xl border border-gray-200 dark:bg-slate-800 dark:text-white">
      <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
      <p className="text-gray-500 mb-2">Updated Date: Jan-01-2025</p>

      <p className="mb-4">Welcome to CollegeMate.io! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our website and mobile application.</p>
      
      <h3 className="text-xl font-semibold mt-6">1. Information We Collect</h3>
      <h4 className="font-semibold mt-2">a. Personal Information:</h4>
      <ul className="list-disc pl-5 mb-4">
        <li>Name, email address, phone number</li>
        <li>Educational details (if provided by the user)</li>
        <li>Payment details (processed securely via third-party providers)</li>
      </ul>
      
      <h4 className="font-semibold">b. Non-Personal Information:</h4>
      <ul className="list-disc pl-5 mb-4">
        <li>Device information (e.g., model, OS version, IP address)</li>
        <li>App usage data, cookies, and analytics</li>
      </ul>
      
      <h3 className="text-xl font-semibold mt-6">2. How We Use Your Information</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>To provide and improve our services</li>
        <li>To personalize user experience</li>
        <li>To process payments and subscriptions</li>
        <li>To send important notifications and updates</li>
        <li>To comply with legal requirements</li>
        <li>To enhance security and prevent fraud</li>
      </ul>
      
      <h3 className="text-xl font-semibold mt-6">3. Sharing of Information</h3>
      <p className="mb-4">We do not sell or rent your personal data. However, we may share your information with:</p>
      <ul className="list-disc pl-5 mb-4">
        <li>Service providers for payment processing, analytics, and hosting</li>
        <li>Legal authorities if required by law</li>
        <li>Third-party integrations such as Google Analytics and Firebase for analytics and performance tracking</li>
      </ul>
      
      <h3 className="text-xl font-semibold mt-6">4. Data Security</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Data encryption for sensitive information</li>
        <li>Secure payment processing through trusted providers</li>
        <li>Regular security audits and compliance checks</li>
        <li>Strict access control policies for personal data</li>
      </ul>
      
      <h3 className="text-xl font-semibold mt-6">5. Data Retention & Deletion Policy</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>We retain personal data as long as necessary to provide our services.</li>
        <li>Users may request deletion of their accounts and associated data by contacting us at <span className="text-blue-600">support@collegemate.io</span>.</li>
        <li>We anonymize or delete data when it is no longer required.</li>
      </ul>
      
      <h3 className="text-xl font-semibold mt-6">6. Your Rights</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Access, update, or delete your data</li>
        <li>Opt-out of marketing communications</li>
        <li>Disable cookies through browser settings</li>
        <li>Request a copy of collected data</li>
      </ul>
      
      <h3 className="text-xl font-semibold mt-6">7. Third-Party Services</h3>
      <p className="mb-4">Our app may contain links to third-party websites/services. We are not responsible for their privacy practices. These include but are not limited to:</p>
      <ul className="list-disc pl-5 mb-4">
        <li>Google Analytics (for usage analytics)</li>
        <li>Firebase (for authentication and app performance tracking)</li>
        <li>Payment gateways (for secure transaction processing)</li>
      </ul>
      
      <h3 className="text-xl font-semibold mt-6">8. Compliance with Legal Regulations</h3>
      <ul className="list-disc pl-5 mb-4">
        <li><span className="font-semibold">GDPR:</span> General Data Protection Regulation for users in the EU</li>
        <li><span className="font-semibold">CCPA:</span> California Consumer Privacy Act for users in California</li>
        <li><span className="font-semibold">COPPA:</span> Children’s Online Privacy Protection Act for users under 13</li>
      </ul>
      
      <h3 className="text-xl font-semibold mt-6">9. Children's Privacy</h3>
      <p className="mb-4">Our services are not intended for users under 13. We do not knowingly collect data from children. If we learn that we have collected such data, we will take immediate steps to delete it.</p>
      
      <h3 className="text-xl font-semibold mt-6">10. Changes to This Policy</h3>
      <p className="mb-4">We may update this policy from time to time. Continued use of our services means acceptance of the updated policy. Users will be notified of major changes via email or app notifications.</p>
      
      <h3 className="text-xl font-semibold mt-6">11. Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at:</p>
      <p className="mt-2"><span className="font-semibold">Email:</span> <a href="mailto:support@collegemate.io" className="text-blue-600">support@collegemate.io</a></p>
      <p className="mt-1"><span className="font-semibold">Website:</span> <a href="https://collegemate.io" className="text-blue-600">https://collegemate.io</a></p>
    </div>
  );
};

export default Pp;
