import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import authApi from '../App/API/auth'; // Import the API functions

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset error
    setSuccessMessage(''); // Reset success message

    try {
      const response = await authApi.handleForgotPassword(email);

      // Check if the response status is OK (2xx)
      if (response.status < 200 || response.status >= 300) {
        throw new Error('Network response was not ok');
      }

      const data = response.data; // Get the response data from the Axios response

      console.log('authApi response:', data); // Log the actual response data

      if (data.message === 'Password reset email sent successfully') {
        setSuccessMessage('Please check your email for password reset instructions.');
      } else {
        setError(data.message); // Display error if the message is not a success
      }
    } catch (error) {
      console.error('Error during password reset:', error); // Log the error
      setError('Something went wrong. Please try again later.'); // Set a user-friendly error message
    }
  };

  const handleCancel = () => {
    setEmail(''); // Reset email field
    setError(''); // Reset error message
    setSuccessMessage(''); // Reset success message
    navigate('/login'); // Navigate back to the login page
  };

  return (
    <div className="flex h-screen items-center justify-center bg-white dark:bg-slate-950">
      <div className="flex flex-col justify-center p-6 w-full md:w-1/2 lg:w-1/3 bg-white dark:bg-slate-950 rounded-lg shadow-lg dark:shadow-none border border-purple-500 dark:border-cyan-300">
        <h2 className="mb-5 text-2xl sm:text-3xl font-bold text-center text-gray-900 dark:text-white">Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="space-y-6">
            <div className="relative">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full p-3 border rounded-md focus:outline-none focus:border-cyan-500 dark:bg-slate-800 dark:text-white dark:border-slate-600"
                required
              />
            </div>
            
            <div className="flex justify-between mt-4">
              <button
                type="submit"
                className="w-full bg-cyan-500 text-white p-3 px-2 rounded-lg hover:bg-cyan-600 dark:bg-purple-600 dark:hover:bg-cyan-700"
              >
                Send Reset Link
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="ml-4 w-full bg-gray-300 text-black p-3 px-2 rounded-lg hover:bg-gray-400 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
