import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing Eye icons
import authApi from '../App/API/auth';
import { fetchColleges } from '../App/API/collegeAuth';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [collegeID, setCollegeID] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [agreed, setAgreed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [colleges, setColleges] = useState([]); // Initialize as an empty array
    const [selectedCollege, setSelectedCollege] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchCollegesData = async () => {
            try {
                const collegeList = await fetchColleges();
                setColleges(collegeList || []); // Ensure we don't set `undefined` to colleges
            } catch (error) {
                console.error('Error fetching colleges:', error);
                setErrorMessage('Failed to load colleges.');
            }
        };
        fetchCollegesData();
    }, []);

    const validatePassword = () => {
        // Basic password validation: min 8 characters, 1 number
        const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
        return regex.test(password);
    };

    const handleSignup = async () => {
        if (!username || !email || !password || !collegeID || !selectedCollege || !profileImage) {
            setErrorMessage('Please fill in all fields and upload a profile image.');
            return;
        }

        if (!validatePassword()) {
            setErrorMessage('Password must be at least 8 characters long and contain at least one number.');
            return;
        }

        try {
            setLoading(true);

            // Prepare the user data
            const userData = {
                username,
                email,
                password,
                collegeID: selectedCollege, // Send the selected college ID here
                college: selectedCollege,   // Send the college name/ID for backend
                profileImage,               // The profile image file
            };

            // Make the API call to register the user
            const response = await authApi.registerUser(userData);

            if (response.success) {
                console.log('Signup successful:', response);
                setSuccessMessage('Signup successful! You can now log in.');
                setUsername('');
                setEmail('');
                setPassword('');
                setCollegeID('');
                setProfileImage(null);
                setAgreed(false);
                setSelectedCollege(null);
            } else {
                setErrorMessage(response.error || 'Something went wrong, please try again.');
            }
        } catch (error) {
            console.error('Error during signup:', error);
            setErrorMessage('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex overflow-hidden flex-col md:flex-row items-center justify-center dark:bg-slate-950">
            <div className="flex flex-col justify-center p-9 w-full md:w-1/2 lg:w-1/3 bg-white dark:bg-slate-950">
                <h2 className="mb-5 text-2xl sm:text-3xl md:text-4xl font-semibold uppercase tracking-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-700 to-cyan-500 text-center">
                    Create your free account
                </h2>
                <p className="mb-4 text-1xl text-gray-700 dark:text-gray-300 text-center">
                    Join our collegemate community to connect, share experiences, and access exclusive resources!
                </p>

                {errorMessage && <div className="text-red-500 text-center mb-4">{errorMessage}</div>}
                {successMessage && <div className="text-green-500 text-center mb-4">{successMessage}</div>}

                <form className="space-y-6">
                    {/* Username Field */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-user text-gray-400"></i>
                            </div>
                            <InputText
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter your username"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    {/* College Name Field */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-id-card text-gray-400"></i>
                            </div>
                            <InputText
                                id="collegeName"
                                value={collegeID}
                                onChange={(e) => setCollegeID(e.target.value)}
                                placeholder="Enter your college ID"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    {/* Email Field */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-envelope text-gray-400"></i>
                            </div>
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    {/* Password Field */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-lock text-gray-400"></i>
                            </div>
                            <InputText
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                            <div className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <FaEyeSlash className="text-gray-500" /> : <FaEye className="text-gray-500" />}
                            </div>
                        </div>
                    </div>

                    {/* College Select Dropdown */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-id-card text-gray-400"></i>
                            </div>
                            <select
                                id="colleges"
                                value={selectedCollege}
                                onChange={(e) => setSelectedCollege(e.target.value)}
                                className="p-inputtext w-full pl-10 pr-4 text-black dark:text-gray-500 bg-gray-50 dark:bg-gray-900 placeholder-gray-500 transition-all duration-200 border border-purple-300 dark:border-blue-200 rounded-md p-3 bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            >
                                <option value="">Select your college</option>
                                {colleges.length > 0 ? (
                                    colleges.map((college) => (
                                        <option key={college._id} value={college._id}>
                                            {college.name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>No colleges available</option>
                                )}
                            </select>
                        </div>
                    </div>

                    {/* College Photo Upload */}
                    <div className="space-y-5">
    <div className="relative flex justify-center items-center border-2 border-dashed border-purple-300 p-6 rounded-md bg-gray-50 dark:bg-gray-900 focus-within:border-purple-600 hover:border-purple-500 cursor-pointer">
        {/* Image Preview */}
        {profileImage ? (
            <img
                src={URL.createObjectURL(profileImage)}
                alt="Profile Preview"
                className="w-24 h-24 rounded-full object-cover"
            />
        ) : (
            <div className="flex flex-col justify-center items-center">
                <i className="pi pi-image text-3xl text-purple-600"></i>
                <p className="text-gray-600 dark:text-gray-300 mt-2">Upload your College ID</p>
            </div>
        )}

        {/* File Input */}
        <input
            id="profileImage"
            type="file"
            onChange={(e) => setProfileImage(e.target.files[0])}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            accept="image/*"
        />
    </div>
</div>


                    {/* Terms and Conditions Checkbox */}
                    <div className="space-y-5">
                        <div className="flex items-center">
                            <Checkbox
                                inputId="agreed"
                                checked={agreed}
                                onChange={(e) => setAgreed(e.checked)}
                            />
                            <label htmlFor="agreed" className="ml-2 text-gray-700 dark:text-gray-300">
                                I agree to the{" "}
                                <Link to="/tc" className="text-purple-600 underline">Terms</Link> and{" "}
                                <Link to="/pp" className="text-purple-600 underline">Privacy Policy</Link>.
                            </label>
                        </div>
                    </div>

                    {/* Signup Button */}
                    <Button
                        label={loading ? 'Signing Up...' : 'Sign Up'}
                        onClick={handleSignup}
                        className="w-full p-3 text-white bg-gradient-to-r from-purple-700 to-cyan-500 hover:bg-gradient-to-l rounded-md"
                        disabled={loading}
                    />
                </form>

                <p className="mt-4 text-center">
                    Already have an account?{" "}
                    <Link to="/login" className="text-purple-600 hover:underline">
                        Login
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default Signup;
