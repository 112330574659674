import React, { useEffect, useState } from 'react';

const Footer = () => {
    const [showScrollUp, setShowScrollUp] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollUp(true);
        } else {
            setShowScrollUp(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <footer className="py-12 mt-12 bg-white dark:bg-slate-950 text-slate-800 dark:text-white sm:py-16">
            <div className="flex flex-wrap justify-between gap-12 px-6 mx-auto space-y-12 max-w-7xl lg:px-8 sm:space-y-0">
                {/* Logo and Description */}
                <div className="flex flex-col items-center w-full mb-8 space-y-4 text-left md:w-1/4 md:items-start">
                    <div
                        className="mx-auto header-logo sm:mx-0"
                        aria-label="Company Logo"
                    ></div>
                    <p className="max-w-xs mt-6 text-base font-semibold leading-relaxed text-center text-slate-600 dark:text-slate-300 md:text-left">
                        Collegemate Community connects students globally to share insights, grow, and thrive together in a supportive community.
                    </p>
                </div>

                {/* Support Section */}
                <div className="w-full mb-8 text-left md:w-1/5">
                    <h4 className="mb-4 text-lg font-semibold text-purple-700 dark:text-white">Support</h4>
                    <ul className="space-y-3 text-sm font-semibold text-slate-600 dark:text-slate-300">
                      
                        <li><a href="/pp" className="hover:text-teal-500 dark:hover:text-teal-400">Privacy Policy</a></li>
                        <li><a href="/tc" className="hover:text-teal-500 dark:hover:text-teal-400">Terms & Conditions</a></li>
                        <li><a href="/deactivation" className="hover:text-teal-500 dark:hover:text-teal-400">Deactivation</a></li>
                    </ul>
                </div>

                {/* Contact Information */}
                <div className="w-full text-left md:w-1/4">
                    <h4 className="mb-4 text-lg font-semibold text-purple-700 dark:text-white">Get in Touch</h4>
                    <div className="space-y-3 text-sm font-semibold text-slate-600 dark:text-slate-300">
                        <p><strong>Phone:</strong> +91-8105827558</p>
                        <p><strong>Email:</strong> <a href="mailto:support@collegemate.io" className="hover:text-teal-500 dark:hover:text-teal-400">support@collegemate.io</a></p>
                        <p><strong>Location:</strong> Bengaluru, India</p>
                    </div>
                    <div className="flex mt-4 space-x-5 text-slate-600 dark:text-slate-300">
                        <a href="https://facebook.com" className="hover:text-blue-500 dark:hover:text-blue-400"><i className="pi pi-facebook text-1xl"></i></a>
                        <a href="https://twitter.com" className="hover:text-blue-400 dark:hover:text-blue-300"><i className="pi pi-twitter text-1xl"></i></a>
                        <a href="https://www.linkedin.com/company/collegemateio/" className="hover:text-blue-700 dark:hover:text-blue-600"><i className="pi pi-linkedin text-1xl"></i></a>
                        <a href="https://www.instagram.com/collegemate.io" className="hover:text-pink-500 dark:hover:text-pink-400"><i className="pi pi-instagram text-1xl"></i></a>
                    </div>
                </div>

                {/* App Download Section */}
                <div className="w-full mb-8 text-left md:w-1/5">
                    <h4 className="mb-4 text-lg font-semibold text-slate-900 dark:text-white">Download the App</h4>
                    <div className="flex mt-2 space-x-5">
                        <a href="https://play.google.com/store/apps/details?id=com.creativestarjsp.collegemate" target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                                alt="Get it on Google Play"
                                className="h-10 hover:opacity-75"
                            />
                        </a>
                       
                    </div>
                </div>
            </div>

            {/* Scroll to Top Button */}
            {showScrollUp && (
                <button
                    onClick={scrollToTop}
                    className="fixed p-2 text-white transition-transform transform rounded-full shadow-lg bottom-5 right-5 bg-violet-600 hover:shadow-xl hover:scale-105"
                    aria-label="Scroll to top"
                >
                    <svg width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2l-10 10h6v8h8v-8h6l-10-10z" />
                    </svg>
                </button>
            )}

            {/* Footer Bottom Section */}
            <div className="pt-6 mt-10 text-sm text-center dark:border-slate-700 text-slate-600 dark:text-slate-300">
                <p className="text-base font-medium">
                    &copy; {new Date().getFullYear()} <strong className="text-purple-600 dark:text-purple-400">Collegemate Community</strong> All rights reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
