

import React from 'react';
const mentors = [
    {
        id: 1,
        name: "John Doe",
        avatar: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=400",
        field: "Software Engineering",
        followers: 1200,
        bio: "Experienced software engineer specializing in web development and cloud computing.",
    },
    {
        id: 2,
        name: "jiya gowtham",
        avatar: "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&w=400",
        field: "Data Science",
        followers: 950,
        bio: "Data scientist with a passion for machine learning and data visualization.",
    },
    {
        id: 3,
        name: "Jane Smith",
        avatar: "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=400",
        field: "Data Science",
        followers: 950,
        bio: "Data scientist with a passion for machine learning and data visualization.",
    },
    {
        id: 4,
        name: "Alex Williams",
        avatar: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=600",
        field: "Machine Learning",
        followers: 1300,
        bio: "Machine learning engineer focused on artificial intelligence and predictive modeling.",
    },
    {
        id: 5,
        name: "Sophia Turner",
        avatar: "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&w=400",
        field: "Cybersecurity",
        followers: 1100,
        bio: "Cybersecurity expert with experience in network security and ethical hacking.",
    },
    {
        id: 6,
        name: "Liam Brown",
        avatar: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600",
        field: "Cloud Computing",
        followers: 1150,
        bio: "Cloud computing specialist working with AWS, Azure, and scalable infrastructure.",
    }

    // Add more mentor objects as needed
];



const meetings = [
    {
        id: 1,
        name: "Alice Williams",
        avatar: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600",
        position: "Project Manager",
        topic: "Effective Project Management",
        date: "Oct 15, 2023",
        type: "Free",
        stars: 4.5,
    },
    {
        id: 2,
        name: "Robert Thompson",
        avatar: "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=400",
        position: "Senior Developer",
        topic: "Advanced React Techniques",
        date: "Oct 18, 2023",
        type: "Paid",
        stars: 4.8,
    },
    {
        id: 3,
        name: "Robert Thompson",
        avatar: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600",
        position: "Senior Developer",
        topic: "Advanced React Techniques",
        date: "Oct 18, 2023",
        type: "Paid",
        stars: 4.8,
    },
    {
        id: 4,
        name: "Rayan",
        avatar: "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=400",
        position: "Senior Developer",
        topic: "Advanced React Techniques",
        date: "Oct 18, 2023",
        type: "Paid",
        stars: 4.8,
    },
    // Add more meeting objects as needed
];

const Mentorship = () => {
    // const [subscriptions, setSubscriptions] = useState(mentors);

    const handleFollow = (id) => {
        // Implement follow functionality
        console.log("Followed mentor ID:", id);
    };

    const handleSubscribe = (id) => {
        // Implement subscribe functionality
        console.log("Subscribed to mentor ID:", id);
    };

    return (
        <>
            <div className="mb-6">
                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className="text-purple-600">Mentor-Dashboard</span>
                </div>
            </div>

            <div className="min-h-screen bg-gray-100 dark:bg-slate-950 text-gray-900 dark:text-white p-6 px-4 md:px-10">
                {/* Mentors Section */}
                <div className="flex items-center justify-between">
                    <h2 className="text-2xl font-bold mb-6 text-left">Mentors</h2>
                    <a href="#" className="text-blue-600 hover:underline text-sm">See All</a>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {mentors.map((mentor) => (
                        <div
                            key={mentor.id}
                            className="bg-white dark:bg-slate-900 rounded-lg p-5 shadow hover:shadow-lg transition text-center"
                        >
                            <div className="flex flex-col items-center mb-4">
                                <img
                                    src={mentor.avatar}
                                    alt={mentor.name}
                                    className="w-24 h-24 rounded-full object-cover mb-4"
                                />
                                <h3 className="text-lg font-semibold">{mentor.name}</h3>
                                <p className="text-sm text-gray-500 dark:text-gray-400">{mentor.field}</p>
                                <p className="text-xs text-gray-400">{mentor.followers} Followers</p>
                            </div>
                            <p className="text-gray-600 dark:text-gray-400 text-center mb-4 text-sm">{mentor.bio}</p>
                            <div className="flex justify-center mt-4 space-x-2">
                                <button
                                    onClick={() => handleFollow(mentor.id)}
                                    className="w-1/2 py-2 border border-purple-600 rounded-md text-purple-600 hover:bg-purple-100 transition"
                                >
                                    <i className="pi pi-user-plus mr-2"></i> Follow
                                </button>
                                <button
                                    onClick={() => handleSubscribe(mentor.id)}
                                    className="w-1/2 py-1 bg-purple-600 text-white rounded-md hover:bg-purple-400 transition"
                                >
                                    ₹ Subscribe
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Manage Subscriptions Section */}


                {/* Upcoming Meetings Section */}
                <div className="mt-10">
                    <div className="flex items-center justify-between">
                        <h2 className="text-2xl font-bold mb-6 text-left">Past Meetings</h2>
                        <a href="#" className="text-blue-600 hover:underline text-sm">See All</a>
                    </div>

                    <div className="overflow-x-auto bg-white dark:bg-slate-900 rounded-lg shadow-sm">
                        <table className="min-w-full text-left border-collapse dark:border-gray-800 ">
                            <thead>
                                <tr className="border-b text-gray-600 dark:text-gray-400">
                                    <th className="p-4 font-semibold">Name</th>
                                    <th className="p-4 font-semibold">Position</th>
                                    <th className="p-4 font-semibold">Topic</th>
                                    <th className="p-4 font-semibold">Date</th>
                                    <th className="p-4 font-semibold">Type</th>
                                    <th className="p-4 font-semibold">Rating</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-900 dark:text-white">
                                {meetings.map((meeting) => (
                                    <tr key={meeting.id} className="border-b dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-slate-800">
                                        <td className="p-4 flex items-center gap-2">
                                            <img
                                                src={meeting.avatar}
                                                alt={meeting.name}
                                                className="w-10 h-10 rounded-full object-cover"
                                            />
                                            <span>{meeting.name}</span>
                                        </td>
                                        <td className="p-4">{meeting.position}</td>
                                        <td className="p-4 text-sm">{meeting.topic}</td>
                                        <td className="p-4 text-sm">{meeting.date}</td>
                                        <td className="p-4">
                                            <span
                                                className={`px-2 py-1 rounded-full text-xs font-semibold ${meeting.type === "Free" ? "bg-blue-100 text-blue-700" :
                                                    meeting.type === "Paid" ? "bg-green-100 text-green-700" :
                                                        "bg-gray-100 text-gray-700"
                                                    }`}
                                            >
                                                {meeting.type}
                                            </span>
                                        </td>
                                        <td className="p-4 flex items-center text-yellow-500">
                                            {meeting.stars} <i className="pi pi-star ml-1"></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </>

    );
};

export default Mentorship;
